"use client";
import { heIL } from "@mui/material/locale";
import { ThemeOptions, createTheme } from "@mui/material/styles";
import GlobalStyle from "./GlobalStyle";
import colors from "./colors";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true; // adds the `xxs` breakpoint
  }
}

const muiThemeOptions: ThemeOptions = {
  direction: "rtl",
  palette: {
    mode: "light",
    primary: {
      main: colors.green,
    },
    secondary: {
      main: colors.black,
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 376,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "var(--alomni)",
    h1: {
      fontSize: "32px",
      fontWeight: "700",
      color: colors.white,
      textAlign: "center",
    },
    h2: {
      fontSize: 20,
      textAlign: "center",
      fontWeight: "bold",
      color: colors.black,
      letterSpacing: "0.72px",
      margin: "16px 0",
    },
    h3: {
      fontSize: 16,
      color: colors.black,
      textAlign: "center",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: GlobalStyle,
    },
  },
};
const theme = {
  ...createTheme(muiThemeOptions, heIL),
  colors,
};
export default theme;
