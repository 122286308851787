import colors from "@/theme/colors";
import sizes from "@/theme/sizes";
import theme from "@/theme/theme";
import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import Image from "next/image";
import { Dispatch, SetStateAction } from "react";
import { LottieModal } from "../Layout/LottieModal";
import { ProcessStatus } from "../component.modal";
import animationData from "./modal_connect.json";

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  background-color: ${colors.white};
  padding-bottom: 20px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
  ${theme.breakpoints.down("sm")} {
    width: 90%;
    justify-content: flex-start;
  }
`;

const ButtonWrapper = styled.div<{ process: boolean }>`
  display: flex;
  justify-content: ${process ? "center" : "space-between"};
  align-items: center;
  flex-direction: row;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  left: 0px;
`;

const StyledButton = styled(Button)(() => ({
  borderRadius: sizes.button.rounded.sm.borderRadius,
  display: "flex",
  margin: 10,
}));

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface MdalProps {
  setError: Dispatch<SetStateAction<boolean>>;
  msgHeader: string;
  msgDiscription: string;
  status?: ProcessStatus;
  handleClose: () => void;
  hideReturnButton?: boolean;
  backButtonClick?: () => void;
}

export enum HanldleClose {
  "backToHome",
  "stayOnPage",
  "contactUs",
}

const LottieWrapper = styled.div`
  width: 100px;
`;

export const Modal = ({
  setError,
  msgHeader,
  msgDiscription,
  status,
  handleClose,
  hideReturnButton,
  backButtonClick,
}: MdalProps) => {
  return (
    <ModalWrapper>
      <TextWrapper>
        <CloseButton onClick={() => handleClose()}>
          <Image
            src={"./x_close_button.svg"}
            alt="close button"
            width={30}
            height={30}
          />
        </CloseButton>
        <LottieWrapper>
          <LottieModal animationData={animationData} />
        </LottieWrapper>
        <Typography variant="h1" fontSize={30} color={colors.black}>
          {msgHeader}
        </Typography>
        <Typography marginTop={2} variant="h3" fontSize={20} lineHeight={2}>
          {msgDiscription}
        </Typography>
      </TextWrapper>
      <ButtonWrapper process={status === ProcessStatus.Error ? true : false}>
        {hideReturnButton ? null : (
          <StyledButton
            onClick={() => {
              setError(false);
              handleClose();
              backButtonClick && backButtonClick();
            }}
            variant="contained"
            color="primary"
          >
            <Typography variant="button" fontSize={20} fontWeight={700}>
              חזור להזנת נתונים
            </Typography>
          </StyledButton>
        )}
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export const ErrorMoadlWrapper = styled.div`
  position: absolute;
  top: 25%;
  z-index: 4;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;
