"use client";

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    });
  }
};

export const trackCustomEvent = (event: string, package_type?: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event,
      package_type,
    });
  }
};

// export const trackHeaderClick = (
//   elementClicked: string,
//   pageLocation: string
// ) => {
//   if (typeof window.dataLayer !== "undefined") {
//     window.dataLayer.push({
//       name: "header_nav",
//       header_click: elementClicked,
//       page_location: pageLocation,
//       event: "central_park",
//     })
//   }
// }
