export enum ProcessStatus {
  HaveData = "HaveData",
  WaitingForOTP = "WaitingForOTP",
  Error = "Error",
  TimeOut = "TimeOut",
  NotFound = "NotFound",
  NotMatch = "NotMatch",
  PhoneNotFound = "PhoneNotFound",
  AccountNotFound = "AccountNotFound",
  MeterNotFound = "MeterNotFound",
  PersonIdError = "PersonIdError",
  Success = "Success",
}
