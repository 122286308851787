const colors = {
  black: "#000000",
  green: "#01F156",
  gray: "#606060",
  red: "#FF0000",
  white: "#FFFFFF",
  lightGrey: "#F8F8F8",
  svgGrey: "#BFBFBF",
  errorRed: "#BE2626",
};

export default colors;
