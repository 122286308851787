import dynamic from "next/dynamic";
import Image from "next/image";

export const LottieModal = dynamic(() => import("lottie-react"), {
  ssr: false,
  loading: () => (
    <Image
      width={100}
      height={100}
      priority
      src="./success_v.svg"
      alt={"electra-power-connect"}
    />
  ),
});
