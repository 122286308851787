const sizes = {
  header: {
    headerHeight: "72px",
    paddingHeader: "20px",
  },
  button: {
    rounded: {
      sm: {
        width: 124,
        height: 40,
        borderRadius: 30,
      },
      lg: {
        width: 400,
        height: 50,
        borderRadius: 50,
      },
    },
    circle: {
      width: 40,
      height: 40,
      borderRadius: 50,
    },
  },
  images: {
    logo_sm: {
      width: 262,
      height: 32,
    },
    logo_sm_mobile: {
      width: 180,
      height: 22,
    },
    logo_bg: {
      width: 533,
      height: 65,
    },
  },
};
export default sizes;
